import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Display, Underlined } from '../components/typography';

const Home = () => (
  <Layout>
    <SEO title="Hey! Busy bee" />
    <section
      className="hero max-w-full flex flex-col lg:justify-start h-full mt-2 lg:bg-fixed bg-contain bg-repeat-y bg-center lg:bg-auto lg:bg-no-repeat"
      style={{
        backgroundImage: `url("/svg/circle-480.svg")`,
      }}
    >
      <h1 className="text-center lg:text-left lg:flex lg:flex-row lg:border-b-4 lg:pb-8 lg:mb-6 2xl:mb-8 border-black">
        <Display as="span" className="block">
          Wel<span className="block">come</span>
        </Display>
        <span className="flex flex-col lg:flex-grow lg:text-center">
          <span className="lg:block  mt-auto ">
            <Underlined as="span" className="inline-block">
              <span className="body-2 uppercase">To Kyosimba</span>
            </Underlined>
          </span>
        </span>
        <span className="flex flex-col">
          <span className="lg:block lg:mt-auto">
            <span
              className="block headline-4 lg:headline-1"
              style={{ lineHeight: '3rem' }}
            >
              Busy Bee
            </span>
          </span>
        </span>
      </h1>
      <p className="text-base font-headline text-center my-4 lg:headline-3 lg:text-left">
        Transmedia Storytelling
        <span className="block lg:mt-2">
          Data Visualization and Research Development
        </span>
      </p>
    </section>
  </Layout>
);
export default Home;
